import { Injectable } from "@angular/core";
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Intercom } from "ng-intercom";
import { Router } from "@angular/router";
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { AdminHttp } from "./admin-http.service";
import * as moment from 'moment';

@Injectable()
export class AdminAuthService {
    uid: string;
    email: string;

    // we cant remove AngularFireDatabase from this constructor as it ends creshing FirebaseAuthentication plugin
    constructor(
        public db: AngularFireDatabase,
        public intercom: Intercom,
        private router: Router,
        private http: AdminHttp
    ) {
        this.http.logoutUser.subscribe(res => {
            if (res) {
                this.logout();
            }
        })
    }

    googleLogin(): Promise<any> {
        return new Promise(resolve => {
            FirebaseAuthentication.signInWithGoogle({
                customParameters: [{
                    key: 'prompt',
                    value: 'select_account'
                  }]
            }).then(userInfo => {
                if (!userInfo.user.email.includes('extemporeapp.com')) {
                    resolve({success: false, error: 'Not extempore user'});
                    return;
                }

                this.http.get<any>(`auth/check-user/${userInfo.user.uid}`).subscribe(checkUserRes => {
                    if (!checkUserRes || !checkUserRes.success) {
                        resolve({success: false, error: checkUserRes.error});
                        return;
                    }

                    this.uid = userInfo.user.uid;
                    this.email = userInfo.user.email;
                    localStorage.setItem('adminUid', JSON.stringify(this.uid));
                    localStorage.setItem('adminEmail', JSON.stringify(this.email));

                    this.http.post<any>(`auth/update-admins-expiery/${userInfo.user.uid}`).subscribe(res => {
                        resolve({success: true});
                    }, err => {
                        resolve({success: false, error: err});
                    })
                }, err => {
                    resolve({success: false, error: err});
                });
            }, err => {
                resolve({success: false, error: err});
            });
        });
    }

    logout() {
        this.uid = null;
        this.email = null
        localStorage.removeItem('adminUid');
        localStorage.removeItem('adminEmail');
        FirebaseAuthentication.signOut();
        this.router.navigateByUrl('/admin/auth');
    }

    checkUser(): Promise<boolean> {
        return new Promise(resolve => {
            if (this.uid && this.email) {
                resolve(true);
                return;
            }

            let uid = JSON.parse(localStorage.getItem('adminUid'));
            let email = JSON.parse(localStorage.getItem('adminEmail'));
            if (!uid || !email) {
                this.router.navigateByUrl('/admin/auth');
                resolve(false);
                return;
            }

            this.http.get<any>(`auth/check-user/${uid}`).subscribe(checkUserRes => {
                if (checkUserRes.success) {
                    if (!checkUserRes.data.ExpiryTime || moment().valueOf() > checkUserRes.data.ExpiryTime) {
                        this.router.navigateByUrl('/admin/auth');
                        resolve(false);
                    } else {
                        this.uid = uid;
                        this.email = email;
                        resolve(true);
                    }
                } else {
                    this.router.navigateByUrl('/admin/auth');
                    resolve(false);
                }
            }, err => {
                this.router.navigateByUrl('/admin/auth');
                resolve(false);
            });
        });
    }
}
