import {of as observableOf,  Observable ,  Subject, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { switchMap, startWith, shareReplay } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Notify } from '../notify.service';
import { DeviceService } from './device.service';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { LoaderService } from './loader.service';
import { NavService } from './nav.service';
import { IonicAlertsService } from 'app/ionic-alerts.service';
import { MatTeacherSsoComponent } from './mat-teacher-sso.component';
import { Location } from '@angular/common';
import { UserSessionService } from './user-session.service';
import firebase from 'firebase/compat/app';
import * as moment from 'moment';
import jwtDecode from "jwt-decode";

// Shim LocalStorage for browsers (Safari < 11 in incognito mode) that don't support it
try {
    if (!window['localStorage']) {
        (<any>window['localStorage']) = {
            removeItem: () => {},
        };
    }
} catch (err) {
    // We tried to shim localStorage on a browser that already supports it.
}

declare var ga: any;

/**
 * All of the User data about the current user
 *
 * Could be null if the user isn't logged in / has logged out
 * Could be a Firebase Auth object if the user is firebase authenticated but we have no data for the user.
 * Could be an token, name, etc
 *
 * TODO:
 * I'd love to get rid of email, displayName, and idGuid because these should come from Extempore
 */
export interface UserAuthData {
    TokenID: string;
    expirationDate: string;
    displayName?: string;
    uid?: string;
    googleUid?: string,
    appleUid?: string,
    email?: string;
    idGuid?: string;
    isInstructorStudent?: boolean;
}

export interface FirebaseStoredUser {
    TokenID: string;
    expirationDate: string;
    classes: any;
    displayName: string;
    username: string;
}

export class FirebaseUserData {
    email: string;
    displayName: string;
    uid: string;
    googleUid: any;
    appleUid: any;

    constructor(email: any, displayName: any, uid: any, googleUid: any, appleUid: any) {
        this.email = email;
        this.displayName = displayName;
        this.uid = uid;
        this.googleUid = googleUid;
        this.appleUid = appleUid;
    }
}

@Injectable()
export class AuthService {
    /**
     * The raw AngularFire auth object
     */
    user: any;

    /**
     * Is the current user connected with some sso
     */
    appleConnected: boolean;
    googleConnected: boolean;
    cleverConnected: boolean;
    classLinkConnected: boolean;

    /**
     * The user's token from firebase or manual sign in
     */
    token = new BehaviorSubject<string>(null);

    /**
     * I'm bad at observables, and want to be able to fetch this synchronously at any time.
     */
    syncToken: string;

    /**
     * Keep data to know if the user logged in with Clever or ClassLink
     */
    ssoUser = new BehaviorSubject<boolean>(false);

    /**
     * All of the User data about the current user
     *
     * Could be null if the user isn't logged in / has logged out
     * Could be a Firebase Auth object if the user is firebase authenticated but we have no data for the user.
     * Could be an token, name, etc
     *
     */
    userAuthData: Observable<UserAuthData | null>;
    /**
     * I'm bad at observables, and want to be able to fetch this synchronously at any time.
     */
    syncUserAuthData: UserAuthData;


    method = new Subject<'firebase' | 'password' | 'autologin' | 'apple' | 'clever' | 'class-link' | 'edlink' | null>();

    /**
     * Track to see if the user is currently logging out.
     */
    isLoggingOut: boolean;

    /**
    * Track if the user changed his username or password in MyAccount
    */
    credentialsChange = false;

    /**
     * Auth events coming from password or autologin
     */
    private passwordAuthEvents = new Subject<UserAuthData | null>();

    /**
     * This two subjects are used to handle socket connections
     */
    userLoggedOut = new Subject<boolean>();
    userLoggedIn = new Subject<boolean>();

    /**
     * Subject to check if the user is instructor student
     */
    isInstructorStudent = new BehaviorSubject<boolean>(false);

    constructor(
        public http: HttpClient,
        public router: Router,
        public notify: Notify,
        public dialog: MatDialog,
        public deviceService: DeviceService,
        private loaderService: LoaderService,
        private navService: NavService,
        private alertService: IonicAlertsService,
        private location: Location,
        private userSessionService: UserSessionService,
    ) {
        const currentPath = this.location.path();
        if (
            currentPath.includes('instructor') ||
            currentPath.includes('admin') ||
            currentPath.includes('demo') ||
            currentPath.includes('new')
        ) {
            return;
        }

        this.setupObservables();
    }

    /**
     * Do this on first load of the service, or after logout to clear out the gunk.
     */
    setupObservables() {
        this.getCurrentFirebaseUser();

        let cacheData = null;
        if (this.testLocalStorage()) {
            // You must use startsWith, otherwise the cache data is emitted before anyone is listening
            cacheData = JSON.parse(localStorage['passwordAuthData'] || '{}');
            // Save method back to local storage
            this.method.subscribe(method => {
                localStorage['authenticationMethod'] = method;
                console.log('overwrote saved auth method with', method);
            });
        }

        // Set the user's data source based on login method
        // Fetch method from local storage to ensure we are using the right auth method to start
        // You will either get some sweet awesome valid auth data
        this.userAuthData = this.method.pipe(
            startWith(this.testLocalStorage() ? localStorage['authenticationMethod'] || null : null),
            switchMap(method => {
                console.log('Got new auth method', method);
                switch (method) {
                    case 'firebase':
                        if (this.user && this.user.uid) {
                            return this.getDataForFirebaseUser(this.user.uid);
                        } else {
                            return observableOf(null);
                        }
                    case 'apple':
                        if (this.user && this.user.uid) {
                            return this.getDataForFirebaseUser(this.user.uid);
                        } else {
                            return observableOf(null);
                        }
                    case 'clever':
                    case 'password':
                    case 'autologin':
                        return this.passwordAuthEvents;
                    default:
                        return observableOf(null);
                }
            }),
            startWith(cacheData),
            shareReplay()
        );

        this.userAuthData.subscribe(userData => {
            if (!userData || !userData.TokenID) {
                this.token.next(null);
                return;
            }

            if (userData.expirationDate && moment().isAfter(userData.expirationDate)) {
                this.token.next(null);
                return;
            }

            this.isInstructorStudent.next(userData.isInstructorStudent);

            this.notify.setUserDetails({ token: userData.TokenID });
            this.token.next(userData.TokenID);
        });

        this.token.subscribe(token => {
            this.syncToken = token;

            if (!token) {
                this.ssoUser.next(false);
                return;
            }

            this.http.get<any>(`${environment.NApiDomain}/sso/sso-check/${token}`).subscribe(ssoRes => {
                if (!ssoRes.success || !ssoRes.didFindSsoLogin) {
                    this.ssoUser.next(false);
                    return;
                }

                this.cleverConnected = !!ssoRes.isCleverUser;
                this.classLinkConnected = !!ssoRes.isClassLinkUser;
                this.googleConnected = !!ssoRes.isGoogleUser;
                this.appleConnected = !!ssoRes.isAppleUser;

                this.ssoUser.next(true);
            });
        });

        this.userAuthData.subscribe(authData => (this.syncUserAuthData = authData));
    }

    async getCurrentFirebaseUser() {
        if (this.user) {
            return;
        }

        try {
            let firebaseCurrentUserRes = await FirebaseAuthentication.getCurrentUser();
            if (!firebaseCurrentUserRes) {
                return;
            }

            const result = await FirebaseAuthentication.getIdToken();
            if (!result || !result.token) {
                return;
            }

            const firebaseUser: any = jwtDecode(result.token);
            this.user = {
                email: firebaseUser.email,
                uid: firebaseUser.user_id,
                googleUid: firebaseUser.firebase?.identities['google.com'] ? firebaseUser.firebase.identities['google.com'][0] : null,
                appleUid: firebaseUser.firebase?.identities['apple.com'] ? firebaseUser.firebase.identities['apple.com'][0] : null
            };
        } catch (error) {
            console.log('getCurrentFirebaseUser error: ', error.message);
        }
    }

    getDataForFirebaseUser(uid: string): Observable<UserAuthData> {
        return this.http.get<UserAuthData>(`${environment.NApiDomain}/get-firebase-user/${uid}`);
    }

    // updateExpirationDateInFirebase(uid) {
    //     this.db.object<FirebaseStoredUser>(`/${environment.FirebaseUserRoot}/${uid}`).update({expirationDate: moment().add(1, 'd').toISOString()});
    // }

    logout(withNavigation: boolean) {
        this.navService.history = [];
        this.loaderService.hideLoading();
        this.isInstructorStudent.next(false);
        if (this.syncToken || this.userAuthData) {
            console.log('There was a logged in user and we are logging him out');

            if (this.deviceService.isApp()) {
                this.userSessionService.userInApp.next({inApp: false, token: this.syncToken});
            }

            this.syncToken = null;
            this.method.next(null);
            this.token.next(null);
            this.userLoggedOut.next(true);
            FirebaseAuthentication.signOut();
            this.isLoggingOut = true;
            if (this.testLocalStorage()) {
                localStorage.removeItem('passwordAuthData');
                localStorage.removeItem('authenticationMethod');
            }
            this.googleConnected = false;
            this.appleConnected = false;
            this.classLinkConnected = false;
            this.cleverConnected = false;
        } else {
            console.log('Logout function was called but there was no logged in user');
        }

        if (withNavigation) {
            this.router.navigate(['/']);
        }
    }

    connectClever(code: string, token: string): Promise<boolean> {
        this.loaderService.showLoading();

        return new Promise((resolve, reject) => {
            this.http.get<any>(`${environment.NApiDomain}/clever/token/${code}/connect`).subscribe(res => {
                this.http.get<any>(`${environment.NApiDomain}/clever/me-data/${res.access_token}`).subscribe(data => {
                    this.connectSso('clever', data.type.toLowerCase(), data.data.id, token).then(result => {
                        this.loaderService.hideLoading();
                        resolve(result);
                    }, err => {
                        this.loaderService.hideLoading();
                        reject({error: err});
                    });
                });
            });
        });
    }

    disconnectClever(token: string): Promise<any> {
        this.loaderService.showLoading();
        return this.disconnectSso('class-link', token);
    }


    connectClassLink(code: string, token: string): Promise<boolean> {
        this.loaderService.showLoading();

        return new Promise((resolve, reject) => {
            this.http.get<any>(`${environment.NApiDomain}/class-link/get-token/${code}`).subscribe(getTokenRes => {
                this.http.get<any>(`${environment.NApiDomain}/class-link/my-info/${getTokenRes.access_token}`).subscribe(myInfoRes => {
                    this.connectSso('class-link', myInfoRes.Role.toLowerCase(), myInfoRes.TenantId + '_' + myInfoRes.LoginId, token).then(result => {
                        this.loaderService.hideLoading();
                        resolve(result);
                    }, err => {
                        this.loaderService.hideLoading();
                        reject({error: err});
                    });
                });
            }, err => {
                this.loaderService.hideLoading();
                reject({error: err});
            });
        });
    }

    disconnectClassLink(token: string): Promise<boolean> {
        this.loaderService.showLoading();
        return this.disconnectSso('class-link', token);
    }

    connectApple(): Promise<string> {
        return new Promise((resolve, reject) => {
            FirebaseAuthentication.signInWithApple().then(result => {
                let firebaseUserData: FirebaseUserData;
                if (result.additionalUserInfo?.profile) {
                    let googleUid = null;
                    let appleUid = null;

                    if (result.additionalUserInfo.providerId === 'google.com') {
                        if (result.additionalUserInfo.profile.id) {
                            googleUid = result.additionalUserInfo.profile.id;
                        } else if (result.additionalUserInfo.profile.sub) {
                            googleUid = result.additionalUserInfo.profile.sub;
                        }
                    }

                    if (result.additionalUserInfo.providerId === 'apple.com') {
                        if (result.additionalUserInfo.profile.id) {
                            appleUid = result.additionalUserInfo.profile.id;
                        } else if (result.additionalUserInfo.profile.sub) {
                            appleUid = result.additionalUserInfo.profile.sub;
                        }
                    }

                    firebaseUserData = new FirebaseUserData(
                        result.additionalUserInfo.profile.email,
                        `${result.additionalUserInfo.profile.given_name} ${result.additionalUserInfo.profile.family_name}`,
                        result.user.uid,
                        googleUid,
                        appleUid
                    );
                } else {
                    this.method.next(null);
                    return;
                }

                this.http.post<any>(`${environment.NApiDomain}/add-firebase-user`, { service: 'apple', tokenId: this.syncToken, uid: firebaseUserData.uid, appleUid: firebaseUserData.appleUid }).subscribe(res => {
                    if (!res.success) {
                        if (res.isAlreadyConnected) {
                            this.alertService.createGenericAlert('Already connected', 'There is a user already connected to this apple email address. Disconnect that one first before connecting this one');
                        } else {
                            console.log('Apple connect error:', res.error);
                            this.alertService.createGenericAlert('Error', res.error);
                        }
                        this.loaderService.hideLoading();
                        resolve(null);
                        return;
                    }

                    this.appleConnected = true;
                    console.log('Apple is connected');
                    this.user = observableOf({
                        TokenID: this.syncToken,
                        displayName: firebaseUserData.displayName,
                        email: firebaseUserData.email,
                        uid: firebaseUserData.uid,
                        googleUid: firebaseUserData.googleUid,
                        appleUid: firebaseUserData.appleUid
                    });

                    resolve(null);
                }, error => {
                    this.method.next(null);
                    console.log('Capacitor sign in with apple error', error);
                    resolve(null);
                });
            }, err => {
                this.method.next(null);
                console.log('Capacitor sign in with apple error', err);
                resolve(null);
            });
        });
    }

    disconnectApple(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (!this.user) {
                this.alertService.createGenericAlert('Error', 'An error occured while trying to fetch your account info. Please relogin again');
                resolve(false);
                return;
            }

            this.user.subscribe(user => {
                if (!user) {
                    this.alertService.createGenericAlert('Error', 'An error occured while trying to fetch your account info. Please relogin again');
                    resolve(false);
                    return;
                }

                if (user.RoleIdPrimary == 5) {
                    this.autoLogin(this.syncToken);
                }

                this.http.delete<any>(`${environment.NApiDomain}/remove-firebase-user/apple/${this.syncToken}`).subscribe(res => {
                    if (!res.success) {
                        console.log('Apple disconnect error:', res.error);
                        this.alertService.createGenericAlert('Error', res.error);
                        resolve(false);
                        return;
                    }

                    user.appleUid = null;
                    this.user = observableOf(user);
                    this.appleConnected = false;
                    console.log('Apple is disconected');
                    resolve(true);
                }, err => {
                    reject(false);
                });
            });
        });
    }

    connectGoogle(token: string): Promise<any> {
        return new Promise((resolve, reject) => {
            FirebaseAuthentication.signInWithGoogle({
                customParameters: [{
                    key: 'prompt',
                    value: 'select_account'
                  }]
            }).then(result => {
                let firebaseUserData: FirebaseUserData;
                if (result.additionalUserInfo?.profile) {
                    let googleUid = null;
                    let appleUid = null;

                    if (result.additionalUserInfo.providerId === 'google.com') {
                        if (result.additionalUserInfo.profile.id) {
                            googleUid = result.additionalUserInfo.profile.id;
                        } else if (result.additionalUserInfo.profile.sub) {
                            googleUid = result.additionalUserInfo.profile.sub;
                        }
                    }

                    if (result.additionalUserInfo.providerId === 'apple.com') {
                        if (result.additionalUserInfo.profile.id) {
                            appleUid = result.additionalUserInfo.profile.id;
                        } else if (result.additionalUserInfo.profile.sub) {
                            appleUid = result.additionalUserInfo.profile.sub;
                        }
                    }

                    firebaseUserData = new FirebaseUserData(
                        result.additionalUserInfo.profile.email,
                        `${result.additionalUserInfo.profile.given_name} ${result.additionalUserInfo.profile.family_name}`,
                        result.user.uid,
                        googleUid,
                        appleUid
                    );
                } else {
                    resolve({success: false, error: `Google sign in failed, we didn't receive user's info`});
                    return;
                }

                this.http.post<any>(`${environment.NApiDomain}/add-firebase-user`, { service: 'google', tokenId: token, uid: firebaseUserData.uid, googleUid: firebaseUserData.googleUid }).subscribe(res => {
                    if (!res.success) {
                        if (res.isAlreadyConnected) {
                            this.alertService.createGenericAlert('Already connected', 'There is a user already connected to this google email address. Disconnect that one first before connecting this one');
                        } else {
                            console.log('Google connect error:', res.error);
                            this.alertService.createGenericAlert('Error', res.error);
                        }
                        this.loaderService.hideLoading();
                        resolve({success: false});
                        return;
                    }

                    this.googleConnected = true;
                    console.log('Google is connected');
                    this.user = observableOf({
                        TokenID: token,
                        displayName: firebaseUserData.displayName,
                        email: firebaseUserData.email,
                        uid: firebaseUserData.uid,
                        googleUid: firebaseUserData.googleUid,
                        appleUid: firebaseUserData.appleUid
                    });

                    resolve({success: true, token: null, user: {displayName: firebaseUserData.displayName, email: firebaseUserData.email}});
                }, error => {
                    this.loaderService.hideLoading();
                    resolve({success: false});
                });
            }, err => {
                this.method.next(null);
                console.log('Sign in with google error', err);
                this.loaderService.hideLoading();
                resolve({success: false});
            });
        });
    }

    disconnectGoogle(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (!this.user) {
                this.alertService.createGenericAlert('Error', 'An error occured while trying to fetch your account info. Please relogin again');
                resolve(false);
                return;
            }

            this.user.subscribe(user => {
                if (!user) {
                    this.alertService.createGenericAlert('Error', 'An error occured while trying to fetch your account info. Please relogin again');
                    resolve(false);
                    return;
                }

                if (user.RoleIdPrimary == 5) {
                    this.autoLogin(this.syncToken);
                }

                this.http.delete<any>(`${environment.NApiDomain}/remove-firebase-user/google/${this.syncToken}`).subscribe(res => {
                    if (!res.success) {
                        console.log('Google disconnect error:', res.error);
                        this.alertService.createGenericAlert('Error', res.error);
                        resolve(false);
                        return;
                    }

                    user.googleUid = null;
                    this.user = observableOf(user);
                    this.googleConnected = false;
                    console.log('Google is disconected');
                    resolve(true);
                }, err => {
                    reject(false);
                });
            });
        });
    }

    /**
     * @param loginFrom student | store - are we logging in from student login or from store
     * Prompt the user to sign in w/ Google
     * Succeed (with token) if they sign in and have an Extempore Account
     * Succeed (with a null object) if they sign in and don't have an account
     * Fail if sign in fails
     */
    firebaseLogin(): Promise<any> {
        this.loaderService.showLoading();
        ga('send', 'event', 'User Sign In', 'firebase');
        this.method.next('firebase');

        return new Promise(resolve => {
            FirebaseAuthentication.signInWithGoogle({
                customParameters: [{
                    key: 'prompt',
                    value: 'select_account'
                  }]
            }).then(result => {
                let firebaseUserData: FirebaseUserData;
                if (result.additionalUserInfo?.profile) {
                    let googleUid = null;
                    let appleUid = null;

                    if (result.additionalUserInfo.providerId === 'google.com') {
                        if (result.additionalUserInfo.profile.id) {
                            googleUid = result.additionalUserInfo.profile.id;
                        } else if (result.additionalUserInfo.profile.sub) {
                            googleUid = result.additionalUserInfo.profile.sub;
                        }
                    }

                    if (result.additionalUserInfo.providerId === 'apple.com') {
                        if (result.additionalUserInfo.profile.id) {
                            appleUid = result.additionalUserInfo.profile.id;
                        } else if (result.additionalUserInfo.profile.sub) {
                            appleUid = result.additionalUserInfo.profile.sub;
                        }
                    }

                    firebaseUserData = new FirebaseUserData(
                        result.additionalUserInfo.profile.email,
                        `${result.additionalUserInfo.profile.given_name} ${result.additionalUserInfo.profile.family_name}`,
                        result.user.uid,
                        googleUid,
                        appleUid
                    );
                } else {
                    resolve({success: false, error: `Google sign in failed, we didn't receive user's info`});
                    return;
                }

                this.getDataForFirebaseUser(firebaseUserData.uid).subscribe(res => {
                    if (!res || !res.TokenID) {
                        this.method.next(null);
                        this.user = {
                            displayName: firebaseUserData.displayName,
                            email: firebaseUserData.email,
                            uid: firebaseUserData.uid,
                            googleUid: firebaseUserData.googleUid,
                            appleUid: firebaseUserData.appleUid
                        };

                        this.loaderService.hideLoading();
                        resolve({success: true, user: firebaseUserData});
                        return;
                    }
                    this.token.next(res.TokenID);

                    const data = {
                        TokenID: res.TokenID,
                        displayName: res.displayName,
                        email: res.email,
                        expirationDate: moment().add(1, 'd').toISOString()
                    };

                    this.user = observableOf({
                        TokenID: res.TokenID,
                        displayName: res.displayName,
                        email: res.email,
                        uid: res.uid,
                        googleUid: res.googleUid,
                        appleUid: res.appleUid
                    });

                    this.googleConnected = !!res.googleUid;
                    this.appleConnected = !!res.appleUid;

                    if (this.testLocalStorage()) {
                        localStorage['passwordAuthData'] = JSON.stringify(data);
                    }

                    this.loaderService.hideLoading();
                    resolve({success: true, token: res.TokenID, user: firebaseUserData});
                }, err => {
                    this.method.next(null);
                    console.log('Sign in with google error', err);
                    this.loaderService.hideLoading();
                    resolve({success: false, error: err});
                });
            }, err => {
                this.method.next(null);
                console.log('Capacitor sign in with google error', err);
                this.loaderService.hideLoading();
                resolve({success: false, error: err.code});
            });
        });
    }

    appleLogin(): Promise<any> {
        ga('send', 'event', 'User Sign In', 'apple');
        this.method.next('apple');
        this.loaderService.showLoading();

        return new Promise(resolve => {
            FirebaseAuthentication.signInWithApple().then(result => {
                let firebaseUserData: FirebaseUserData;
                if (result.additionalUserInfo?.profile) {
                    let googleUid = null;
                    let appleUid = null;

                    if (result.additionalUserInfo.providerId === 'google.com') {
                        if (result.additionalUserInfo.profile.id) {
                            googleUid = result.additionalUserInfo.profile.id;
                        } else if (result.additionalUserInfo.profile.sub) {
                            googleUid = result.additionalUserInfo.profile.sub;
                        }
                    }

                    if (result.additionalUserInfo.providerId === 'apple.com') {
                        if (result.additionalUserInfo.profile.id) {
                            appleUid = result.additionalUserInfo.profile.id;
                        } else if (result.additionalUserInfo.profile.sub) {
                            appleUid = result.additionalUserInfo.profile.sub;
                        }
                    }

                    firebaseUserData = new FirebaseUserData(
                        result.additionalUserInfo.profile.email,
                        result.additionalUserInfo.profile.given_name && result.additionalUserInfo.profile.family_name ? `${result.additionalUserInfo.profile.given_name} ${result.additionalUserInfo.profile.family_name}` : null,
                        result.user.uid,
                        googleUid,
                        appleUid
                    );
                } else {
                    this.method.next(null);
                    return;
                }

                this.getDataForFirebaseUser(firebaseUserData.uid).subscribe(res => {
                    if (!res || !res.TokenID) {
                        this.method.next(null);
                        this.user = {
                            displayName: firebaseUserData.displayName,
                            email: firebaseUserData.email,
                            uid: firebaseUserData.uid,
                            googleUid: firebaseUserData.googleUid,
                            appleUid: firebaseUserData.appleUid
                        };

                        this.loaderService.hideLoading();
                        resolve({success: true, user: firebaseUserData});
                        return;
                    }

                    this.appleConnected = true;
                    this.token.next(res.TokenID);

                    const data = {
                        TokenID: res.TokenID,
                        displayName: res.displayName,
                        email: res.email,
                        expirationDate: moment().add(1, 'd').toISOString()
                    };

                    this.user = observableOf({
                        TokenID: res.TokenID,
                        displayName: res.displayName,
                        email: res.email,
                        uid: res.uid,
                        googleUid: res.googleUid,
                        appleUid: res.appleUid
                    });

                    if (this.testLocalStorage()) {
                        localStorage['passwordAuthData'] = JSON.stringify(data);
                    }

                    this.loaderService.hideLoading();
                    resolve({success: true, token: res.TokenID, user: firebaseUserData});
                }, err => {
                    this.method.next(null);
                    console.log('Sign in with apple error', err);
                    this.loaderService.hideLoading();
                    resolve({success: false, error: err});
                });
            }, err => {
                this.method.next(null);
                console.log('Capacitor sign in with apple error', err.code);
                this.loaderService.hideLoading();
                resolve({success: false, error: err.code});
            });
        });
    }

    cleverLogin(code: string): Promise<any> {
        this.method.next('clever');
        this.loaderService.showLoading();

        return new Promise((resolve, reject) => {
            this.http.get<any>(`${environment.NApiDomain}/clever/token/${code}`).subscribe(res => {
                this.http.get<any>(`${environment.NApiDomain}/clever/me-data/${res.access_token}`).subscribe(data => {
                    this.http.get<any>(`${environment.NApiDomain}/clever/student-data/${res.access_token}/${data.data.id}`).subscribe(cleverStudentData => {
                        if (data.type === 'student') {
                            this.continueStudentSso(
                                'clever',
                                cleverStudentData.data.id,
                                cleverStudentData.data.email,
                                cleverStudentData.data.name.first,
                                cleverStudentData.data.name.last
                            ).then(result => {
                                resolve(result);
                            }, error => {
                                reject(error);
                            });
                        } else if (data.type === 'teacher') {
                            this.continueTeacherSso(
                                'clever',
                                cleverStudentData.data.id,
                                cleverStudentData.data.email,
                                cleverStudentData.data.name.first,
                                cleverStudentData.data.name.last
                            ).then(result => {
                                resolve(result);
                            }, error => {
                                reject(error);
                            });
                        }
                    });
                }, meDataError => {
                    this.loaderService.hideLoading();
                    reject(`Error on getting clever data: ${meDataError.message}`);
                });
            }, tokenError => {
                this.loaderService.hideLoading();
                reject(`Error on getting token: ${tokenError.message}`);
            });
        });
    }

    classLinkLogin(code: string): Promise<any> {
        this.method.next('class-link');
        this.loaderService.showLoading();

        return new Promise((resolve, reject) => {
            this.http.get<any>(`${environment.NApiDomain}/class-link/get-token/${code}`).subscribe(getTokenRes => {
                this.http.get<any>(`${environment.NApiDomain}/class-link/my-info/${getTokenRes.access_token}`).subscribe(myInfoRes => {
                    console.log('ClassLink myInfoRes', myInfoRes);
                    if (myInfoRes.Role === 'Student') {
                        this.continueStudentSso(
                            'class-link',
                            myInfoRes.TenantId + '_' + myInfoRes.LoginId,
                            myInfoRes.Email,
                            myInfoRes.FirstName,
                            myInfoRes.LastName
                        ).then(result => {
                            resolve(result);
                        }, error => {
                            reject(error);
                        });
                    } else if (myInfoRes.Role === 'Teacher') {
                        this.continueTeacherSso(
                            'class-link',
                            myInfoRes.TenantId + '_' + myInfoRes.LoginId,
                            myInfoRes.Email,
                            myInfoRes.FirstName,
                            myInfoRes.LastName
                        ).then(result => {
                            resolve(result);
                        }, error => {
                            reject(error);
                        });
                    }
                }, myInfoError => {
                    this.loaderService.hideLoading();
                    reject({error: `Error on getting my info: ${myInfoError.message}`});
                });
            }, tokenError => {
                this.loaderService.hideLoading();
                reject({error: `Error on getting token: ${tokenError.message}`});
            });
        });
    }

    edlinkLogin(code: string): Promise<any> {
        this.method.next('edlink');
        this.loaderService.showLoading();

        return new Promise((resolve, reject) => {
            this.http.get<any>(`${environment.NApiDomain}/edlink/sso/${code}`).subscribe(res => {
                ga('send', 'event', 'User Sign In', 'edlink');
                this.loaderService.hideLoading();
                resolve(res);
            }, edlinkDataErr => {
                this.loaderService.hideLoading();
                reject(edlinkDataErr);
            });
        });
    }

    /**
     * Do a user lookup by username and password and emit the token or null via dbTokens
     */
    passwordLogin(username, password): Promise<string> {
        // Password sign in? Let's not be logged into Google then
        this.method.next('password');
        this.loaderService.showLoading();

        if (this.testLocalStorage()) {
            localStorage.removeItem('passwordAuthData');
        }
        FirebaseAuthentication.signOut();

        return new Promise<string>((resolve, reject) => {
            this.http.post<any>(`${environment.NApiDomain}/authenticate`, {
                username: username,
                password: password,
            }).subscribe(response => {
                this.loaderService.hideLoading();
                if (response.success) {
                    ga('send', 'event', 'User Sign In', 'password');

                    const data = {
                        TokenID: response.token,
                        displayName: response.firstName + ' ' + response.lastName,
                        firstName: response.firstName,
                        lastName: response.lastName,
                        email: response.email,
                        uid: response.uid,
                        googleUid: response.googleUid,
                        appleUid: response.appleUid,
                        idGuid: response.idGuid,
                        expirationDate: moment().add(1, 'd').toISOString(),
                        isInstructorStudent: response.isInstructorStudent
                    };

                    // Let's check if they are an instructor and forward to CMS
                    if (response.role === 2 || response.role === 12) {
                        // We don't pass an invalid URL because we already checked the credentials
                        this.takeUserToCMS(username, password);
                        return;
                    }

                    if (this.testLocalStorage()) {
                        localStorage['passwordAuthData'] = JSON.stringify(data);
                    }

                    this.googleConnected = !!response.googleUid;
                    this.appleConnected = !!response.appleUid;

                    this.user = observableOf({
                        TokenID: response.token,
                        displayName: response.firstName + ' ' + response.lastName,
                        email: response.email,
                        uid: response.uid,
                        googleUid: response.googleUid,
                        appleUid: response.appleUid
                    });

                    this.isInstructorStudent.next(data.isInstructorStudent);
                    this.passwordAuthEvents.next(data);
                    resolve(response.token);
                } else {
                    this.passwordAuthEvents.next(null);
                    reject('The username or password was incorrect.');
                }
            }, err => {
                this.loaderService.hideLoading();
                reject(err);
            });
        });
    }

    autoLogin(token: string): Promise<string> {
        console.log('Should have just nexted with autologin');
        this.method.next('autologin');
        this.loaderService.showLoading();

        if (this.testLocalStorage()) {
            localStorage.removeItem('passwordAuthData');
        }
        FirebaseAuthentication.signOut();

        return new Promise<string>((resolve, reject) => {
            this.http.post<any>(`${environment.NApiDomain}/authenticate`, { token: token }).subscribe(response => {
                this.loaderService.hideLoading();
                if (response.success) {
                    ga('send', 'event', 'User Sign In', 'autologin');
                    this.token.next(token);
                    this.syncToken = token;

                    const data = {
                        TokenID: response.token,
                        displayName: response.firstName + ' ' + response.lastName,
                        firstName: response.firstName,
                        lastName: response.lastName,
                        email: response.email,
                        idGuid: response.idGuid,
                        uid: response.uid,
                        googleUid: response.googleUid,
                        appleUid: response.appleUid,
                        expirationDate: moment().add(1, 'd').toISOString(),
                        isInstructorStudent: response.isInstructorStudent
                    };

                    // Let's check if they are an instructor
                    if (response.role === 2) {
                        const msg = 'You autologged as a instructor which is currently unsupported';
                        this.alertService.createGenericAlert('Unsupported action', msg);
                        reject(msg);
                        return;
                    }

                    if (this.testLocalStorage()) {
                        localStorage['passwordAuthData'] = JSON.stringify(data);
                    }

                    this.googleConnected = !!data.googleUid;
                    this.appleConnected = !!data.appleUid;

                    this.user = observableOf({
                        TokenID: response.token,
                        displayName: response.firstName + ' ' + response.lastName,
                        email: response.email,
                        uid: response.uid,
                        googleUid: response.googleUid,
                        appleUid: response.appleUid
                    });

                    console.log('providing new autologin data from passwordAuthEvents');
                    this.isInstructorStudent.next(data.isInstructorStudent);
                    this.passwordAuthEvents.next(data);
                    this.getUserData(response.token);
                    resolve(response.token);
                } else {
                    this.passwordAuthEvents.next(null);
                    this.loaderService.hideLoading();
                    reject('The token was incorrect.');
                }
            }, err => {
                this.loaderService.hideLoading();
                reject(err);
            });
        });
    }

    /**
     * Re-render the user's information in toolbar etc
     * This only works if they signed in via password, otherwise we ignore this data anyway
     * This method is used by the profile screen
     */
    updateUserProfile(newProfileData: { token: string; firstName: string; lastName: string; email: string }) {
        return new Promise((resolve, reject) => {
            this.http.post(`${environment.NApiDomain}/${newProfileData.token}/profile`, newProfileData)
            .subscribe(next => {
                const newValue = this.testLocalStorage() ? JSON.parse(localStorage['passwordAuthData'] || '{}') : null;
                newValue.displayName = newProfileData.firstName + ' ' + newProfileData.lastName;
                newValue.email = newProfileData.email;
                this.passwordAuthEvents.next(newValue);
                resolve(true);
            });
        });
    }

    /**
     * Update the user's password
     * This method is used by the profile screen
     */
    updateUserPassword(newPasswordData: { token: string; password: string }) {
        return new Promise((resolve, reject) => {
            this.http.post<any>(`${environment.NApiDomain}/${newPasswordData.token}/profile`, newPasswordData)
            .subscribe(result => {
                if (result.success) {
                    this.credentialsChange = true;
                    resolve(true);
                } else {
                    reject(result.error);
                }
            });
        });
    }

    /**
     * Update the user's username
     * This method is used by the profile screen
     */
    updateUsername(newUsernameData: { token: string; username: string}, isInstructor: boolean) {
        return new Promise((resolve, reject) => {
            this.http.post<any>(`${environment.NApiDomain}/change-username`, {
                TokenID: newUsernameData.token,
                desiredUsername: newUsernameData.username,
                isInstructor: isInstructor
            }).subscribe(result => {
                if (result.success) {
                    this.credentialsChange = true;
                    resolve(true);
                } else {
                    reject(result.error);
                }
            });
        });
    }

    testLocalStorage(): boolean {
        // Safari, in Private Browsing Mode, looks like it supports localStorage but all calls to setItem
        // throw QuotaExceededError. We're going to detect this and just silently drop any calls to setItem
        // to avoid the entire page breaking, without having to do a check at each usage of Storage.
        try {
            localStorage.setItem('localStorage', '1');
            localStorage.removeItem('localStorage');
           return true;
        } catch (e) {
            Storage.prototype._setItem = Storage.prototype.setItem;
            Storage.prototype.setItem = function() {};
            console.log('Your web browser does not support storing settings locally');
            return false;
        }
    }

    takeUserToCMS(username: string, password: string) {
        const confirmAction = () => {
            window.location.replace(
                `${
                    environment.CMSDomain
                }/account/autologin?username=${username}&password=${password}`
            );
        }
        this.alertService.createConfirmAlert('Redirecting', 'We noticed you used instructor credentials.<br/>You will be redirected to the instructor portal', confirmAction);
    }

    getUserData(token: string) {
        this.http.get<any>(`${environment.NApiDomain}/${token}/profile`).subscribe(studentData => {
            if (!studentData.UserName || !studentData.UserName.endsWith('-student')) {
                // Create/Update the Intercom Contact
                this.createUpdateIntercomContact(studentData.CreatedOn, studentData.IntercomInternalContactId, studentData.IntercomExternalContactId, studentData.Email, studentData.FirstName, studentData.LastName, studentData.UserName, studentData.EdlinkId);
            }
        });
    }

    createUpdateIntercomContact(createdOn: string, intercomInternalContactId: string, intercomExternalContactId: string, email: string, firstName: string, lastName: string, username: string, edlinkId: string) {
        if (!intercomInternalContactId) {
            // Create an Intercom Contact
            this.http.post<any>(`${environment.NApiDomain}/create-intercom-contact`, {intercomExternalContactId: intercomExternalContactId, username: username,
                email: email, name: firstName + " " + lastName, role: "student", creationDate: createdOn, edlinkId: edlinkId})
                .subscribe();
        } else {
            // Update the Intercom Contact
            this.http.post<any>(`${environment.NApiDomain}/update-intercom-contact`, {intercomInternalContactId: intercomInternalContactId, username: username,
                email: email, name: firstName + " " + lastName, role: "student", edlinkId: edlinkId})
                .subscribe();
        }
    }

    private continueStudentSso(
        type: 'clever' | 'class-link' | 'edlink',
        ssoUserId: string,
        email: string,
        firstName: string,
        lastName: string
    ): Promise<any>  {
        return new Promise((resolve, reject) => {
            this.http.get<any>(`${environment.NApiDomain}/sso/extempore-token/${type}/${ssoUserId}`).subscribe(userData => {
                if (userData.success) {
                   if (userData.data && userData.data.ExtemporeToken) {
                        if (userData.data.RoleId !== 5) {
                            this.loaderService.hideLoading();
                            reject({error: 'Not a student account'});
                            return;
                        }

                        console.log(`We found a student with that ${type} id. Let's sign him in!`);
                        ga('send', 'event', 'User Sign In', type);
                        this.autoLogin(userData.data.ExtemporeToken);
                        this.loaderService.hideLoading();
                        resolve({extemporeToken: userData.data.ExtemporeToken, role: 'student'});
                    } else {
                        console.log(`We didn\'t find a student with that ${type} id. Let's create one!`);
                        ga('send', 'event', 'User Sign Up', type);

                        this.http.post<any>(`${environment.NApiDomain}/sso/create-student`,{
                            email: email,
                            firstName: firstName,
                            lastName: lastName,
                            ssoType: type,
                            ssoUserId: ssoUserId.toString()
                        }).subscribe(createStudentRes => {
                            if (createStudentRes.success) {
                                this.autoLogin(createStudentRes.token);
                                this.loaderService.hideLoading();
                                resolve({extemporeToken: createStudentRes.token, role: 'student'});
                            } else {
                                this.loaderService.hideLoading();
                                reject({error: createStudentRes.error});
                            }
                        }, err => {
                            this.loaderService.hideLoading();
                            reject({error: `There was a problem with sign up ${err.error}`});
                        });
                    }
                } else {
                    reject({error: userData.error});
                }
            }, err => {
                reject({error: err});
            });
        });
    }

    private continueTeacherSso(
        type: 'clever' | 'class-link' | 'edlink',
        ssoUserId: string,
        email: string,
        firstName: string,
        lastName: string
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.get<any>(`${environment.NApiDomain}/sso/extempore-token/${type}/${ssoUserId}`).subscribe(userData => {
                if (userData.success) {
                   if (userData.data && userData.data.ExtemporeToken) {
                        if (userData.data.RoleId !== 2) {
                            this.loaderService.hideLoading();
                            reject({error: 'Not an instructor account'});
                            return;
                        }

                        console.log(`We found a teacher with that ${type} id. Let's sign him in!`);
                        ga('send', 'event', 'Instructor Sign In', type);

                        // Autologin to CMS
                        const enc = encodeURIComponent;
                        window.location.assign(`${environment.CMSDomain}/account/autologin?username=${enc(userData.data.UserName)}&token=${enc(userData.data.ExtemporeToken)}&oninvalid=${enc(environment.CMSDomain)}`);
                        resolve({extemporeToken: userData.data.ExtemporeToken, role: 'teacher'});
                    } else {
                        this.dialog.open(MatTeacherSsoComponent, { disableClose: true }).afterClosed().subscribe(matDialogRes => {
                            console.log(`We didn\'t find a teacher with that ${type} id. Let's create one!`);
                            ga('send', 'event', 'User Sign Up', type);

                            this.http.post<any>(`${environment.NApiDomain}/instructor/new-teacher-account`, {
                                service: type,
                                FirstName: firstName,
                                LastName: lastName,
                                licenseType: 'Lite',
                                receiptEmail: email,
                                OrganizationId: matDialogRes.isOranization ? matDialogRes.orgId : null,
                                SchoolName: matDialogRes.isOranization ? matDialogRes.orgName : null,
                                ssoUserId: ssoUserId.toString(),
                                role: matDialogRes.role
                            }).subscribe(createInstructorRes => {
                                if (createInstructorRes.success) {
                                    // Autologin to CMS
                                    const enc = encodeURIComponent;
                                    window.location.assign(`${environment.CMSDomain}/account/autologin?username=${enc(createInstructorRes.data.username)}&token=${enc(createInstructorRes.data.token)}&oninvalid=${enc(environment.CMSDomain)}`);
                                    resolve({extemporeToken: createInstructorRes.data.token, role: 'teacher'});
                                } else {
                                    this.loaderService.hideLoading();
                                    reject({error: createInstructorRes.error});
                                }
                            }, err => {
                                this.loaderService.hideLoading();
                                reject({error: `There was a problem with ${type} sign up ${err}`});
                            });
                        });
                    }
                } else {
                    reject({error: userData.error});
                }
            });
        });
    }

    private connectSso(type: 'clever' | 'class-link', role: string, userId: string, token: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.http.get<any>(`${environment.NApiDomain}/sso/sso-check-extempore-account/${type}/${userId}`).subscribe(ssoCheckRes => {
                if (ssoCheckRes.success) {
                    if (ssoCheckRes.accountFound) {
                        reject(`We already found an account connected to the sso.`)
                        return;
                    }

                    this.http.get<any>(`${environment.NApiDomain}/sso/sso-get-role/${token}`).subscribe(roleRes => {
                        let errMsg = `You can't connect a `;
                        if (type === 'clever') {
                            errMsg += 'clever';
                        } else if (type === 'class-link') {
                            errMsg += 'ClassLink';
                        }

                        if (roleRes.roleId === 5 && role !== 'student') {
                            errMsg += ` teacher to extempore student account`;
                            reject(errMsg);
                            return;
                        }

                        if (roleRes.roleId === 2 && role !== 'teacher') {
                            errMsg += ` student to extempore instructor account`;
                            reject(errMsg);
                            return;
                        }

                        this.http.post<any>(`${environment.NApiDomain}/sso/${type}/connect`, {ssoUserId: userId, token: token}).subscribe(res => {
                            if (res.success) {
                                if (type === 'clever') {
                                    this.cleverConnected = true;
                                    console.log('Clever is connected');
                                } else if (type === 'class-link') {
                                    this.classLinkConnected = true;
                                    console.log('ClassLink is connected');
                                }

                                this.ssoUser.next(true);
                                this.loaderService.hideLoading();
                                resolve(true);
                            } else {
                                this.loaderService.hideLoading();
                                reject(res.error)
                            }
                        }, error => {
                            reject(error);
                        });
                    }, err => {
                        reject(err);
                    });
                } else {
                    reject(ssoCheckRes.error);
                }
            }, err => {
                reject({success: false, error: err});
            });
        });
    }

    private disconnectSso(type: 'clever' | 'class-link', token: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.http.post<any>(`${environment.NApiDomain}/sso/${type}/disconnect`, {token: token}).subscribe(res => {
                if (res) {
                    if (type === 'clever') {
                        this.cleverConnected = false;
                        console.log('Clever is disconnected');
                    } else if (type === 'class-link') {
                        this.classLinkConnected = false;
                        console.log('ClassLink is disconnected');
                    }

                    this.ssoUser.next(false);
                    this.loaderService.hideLoading();
                    resolve(true);
                }
            }, err => {
                this.loaderService.hideLoading();
                reject(err);
            });
        });
    }
}
